/*
  Обозначения:
  _a (all) - применяет свойства к элементу и его дочерним элементам
  _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
  _i (important) - применяет свойства с повышенным приоритетом
*/
html, body {
  width: 100%;
}

body {
  background: #fff;
}

.semibold, .semibold_a, .semibold_a * { font-weight: 500; }

span.required { color: #c40e00; font-weight: 700; }

.bgcblue, .bgcblue_h:hover { background-color: #04bda1; }
.bgcblue_i, .bgcblue_h_i:hover { background-color: #04bda1 !important; }
.bgcblue2, .bgcblue2_h:hover { background-color: #7fffec; }
.bgcblue2_i, .bgcblue2_h_i:hover { background-color: #7fffec !important; }
.bgcef, .bgcef_h:hover { background-color: #efefef; }
.bgcef_i, .bgcef_h_i:hover { background-color: #efefef !important; }

.cblue, .cblue_h:hover { color: #04bda1; }
.cblue_i, .cblue_h_i:hover { color: #04bda1 !important; }
.cblue2, .cblue2_h:hover { color: #7fffec; }
.cblue2_i, .cblue2_h_i:hover { color: #7fffec !important; }

.brcblue, .brcblue_h:hover { border-color: #04bda1; }
.brcblue_i, .brcblue_h_i:hover { border-color: #04bda1 !important; }
.brcblue2, .brcblue2_h:hover { border-color: #7fffec; }
.brcblue2_i, .brcblue2_h_i:hover { border-color: #7fffec !important; }

p { margin-bottom: 18px; text-align: justify; }
p + p { margin-top: -2px; }

.link {
  color: #04bda1;
  text-decoration: underline;
  cursor: pointer;
}
  .link:hover {
    text-decoration: none;
  }



.form-popup-container .form-block-container {
  background: #fff;
  max-width: 450px;
  padding: 28px;
}
  .callback-layout .form-block-container {
    font-size: 15px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,.25);
    max-width: 440px;
    padding: 25px 28px 28px;
  }

  .form-block-container > .subsubheader {
    margin-top: -25px;
  }
    .callback-layout .form-block-container > .subsubheader {
      font-weight: 700;
      padding-top: 0px;
      margin-top: 0px;
    }

  .form {
    position: relative;
  }
    .form-row + .form-row {
      margin-top: 15px;
    }
      .form-label {
        color: #505050;
        font-size: 13px;
        font-weight: 300;
        margin-bottom: 5px;
      }
        .callback-layout .form-label {
          font-size: 15px;
        }

      .input-container {
        background-color: #fff;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        box-shadow: inset 0 3px 3px rgba(0,0,0,.15);
        padding: 5px 10px;
        position: relative;
        z-index: 1;


      }
        .input-container.error {
          border-color: #04bda1;
        }

        .input-container input,
        .input-container textarea,
        .input-container select {
          background: transparent;
          font-size: 1em;
          font-weight: 400;
          line-height: 1.3;
          border: 0px;
          padding: 0px;
          margin: 0px;
        }
        .input-container input:not([type="radio"]):not([type="checkbox"]),
        .input-container textarea,
        .input-container select {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
        }

      .form-error {
        position: relative;
      }
        .errorMessage {
          color: #fff;
          background: #04bda1;
          border-radius: 5px;
          font-size: 12px;
          line-height: 1.3;
          padding: 3px 6px;
          margin: 3px 0px 0px;
        }


    .form-row-checkboxlist .form-field > div + div,
    .form-row-radiolist .form-field > div + div {
      margin: 4px 0px 0px;
    }
      .form-row-checkboxlist .form-field > div > label > span,
      .form-row-radiolist .form-field > div > label > span,
      .form-row-agreement .form-field-wrapper {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
        .form-row-checkboxlist .form-field > div > input,
        .form-row-radiolist .form-field > div > input,
        .form-row-agreement .form-field input {
          display: none;
        }

        .form-row-checkboxlist .form-field > div > label > span > span:first-child,
        .form-row-radiolist .form-field > div > label > span > span:first-child,
        .form-row-agreement .form-field > span {
          content: "";
          align-self: flex-start;
          color: transparent;
          font-size: 0px;
          line-height: 0px;
          display: block;
          width: 22px;
          min-width: 22px;
          height: 22px;
          background: #fff;
          border: 1px solid #ccc;
          border-radius: 3px;
          position: relative;
          cursor: pointer;
        }
        .form-row-radiolist .form-field > div > label > span > span:first-child {
          border-radius: 50%;
          width: 18px;
          min-width: 18px;
          height: 18px;
          margin: 0px 2px;
        }
        .form-row-agreement .form-field > span {
          width: 26px;
          min-width: 26px;
          height: 26px;
        }
          .form-row-checkboxlist .form-field > div > label > span > span:first-child:before,
          .form-row-radiolist .form-field > div > label > span > span:first-child:before,
          .form-row-agreement .form-field > span label {
            content: "";
            color: transparent;
            display: block;
            font-size: 0px;
            background: no-repeat 50% 50%/contain transparent;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            margin: auto;
            cursor: pointer;
          }
            .form-row-checkboxlist .form-field > div > input:checked + label > span > span:first-child:before,
            .form-row-agreement .form-field input:checked + span label {
              background-image: url(/i/checkbox-tick.svg);
            }
            .form-row-radiolist .form-field > div > input:checked + label > span > span:first-child:before {
              background: #04bda1;
              border-radius: 50%;
              margin: 5px;
            }

        .form-row-checkboxlist .form-field > div > label > span > span + span,
        .form-row-radiolist .form-field > div > label > span > span + span,
        .form-row-agreement .form-label {
          margin: 0px 0px 0px 10px;
        }
        .form-row-agreement .form-label {
          font-size: 14px;
        }
          .form-row-checkboxlist .form-field > div > label > span > span + span,
          .form-row-radiolist .form-field > div > label > span > span + span,
          .form-row-agreement .form-label,
          .form-row-agreement .form-label label {
            cursor: pointer;
          }


    .form-buttons {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0px 0px 0px -12px;
    }
      .form-button {
        padding: 18px 0px 0px 12px;
      }


.button-style {
  color: #000;
  display: inline-block;
  background: #7fffec;
  /*border: 1px solid #04bda1;*/
  border: none;
  border-radius: 999px;
  box-shadow: 0 5px 5px rgba(0,0,0,.3), inset 0 1px rgba(255,255,255,.31);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  text-align: center;
  min-width: 11em;
  padding: 0.4375em 1.25em;
  cursor: pointer;
  outline: none;
  position: relative;
  white-space: nowrap;
}
  .button-style.with-hand,
  .button-style.with-hand-white {
    margin-bottom: 34px;
    overflow: visible;
  }
    .button-style.with-hand:after,
    .button-style.with-hand-white:after {
      content: "";
      background: url('/i/hand--black.svg') no-repeat 0% 0%/contain transparent;
      display: block;
      width: 22px;
      height: 31px;
      margin-top: 3px;
      position: absolute;
      top: 100%;
      right: 10%;
    }
    .button-style.with-hand-white:after {
      background-image: url('/i/hand--white.svg');
    }


ul.ul, ul.ul-tick, ol.ol {
  margin-top: -6px;
  margin-bottom: 25px;
}
ol.ol { counter-reset: ol-list-counter; }
  ul.ul ul.ul,
  ul.ul ul.ul-tick,
  ul.ul ol.ol,
  ul.ul-tick ul.ul
  ul.ul-tick ul.ul-tick,
  ul.ul-tick ol.ol,
  ol.ol ul.ul,
  ol.ol ul.ul-tick,
  ol.ol ol.ol {
    padding-left: 20px;
    margin-top: 0px;
    margin-bottom: 4px;
  }
  ul.ul li, ul.ul-tick li, ol.ol li {
    padding-top: 6px;
    padding-bottom: 0px;
    position: relative;
  }
  ul.ul li {
    padding-left: 20px;
  }
    ul.ul li:before {
      content: "";
      display: inline-block;
      background: url('/i/ul-list-item.svg') no-repeat 50% 50%/contain transparent;
      margin-right: 10px;
      margin-left: -20px;
      width: 10px;
      height: 1em;
      vertical-align: middle;
    }
  ul.ul-tick li {
    padding-left: 22px;
  }
    ul.ul-tick li:before {
      content: "";
      background: url('/i/ul-list-item-tick.svg') no-repeat 50% 50%/contain transparent;
      display: block;
      width: 14px;
      height: 12px;
      position: absolute;
      top: 10px;
      left: 0px;
    }
    ol.ol li:before {
      content: counters(ol-list-counter, ".") ". ";
      counter-increment: ol-list-counter;
      color: #04bda1;
    }



.unwrapper {
  margin-left: -9999px;
  margin-right: -9999px;
  position: static;
}
.wrapper {
  width: 1500px;
  margin: 0px auto;
  padding: 0px;
  position: relative;
}


/* Structure */
.structure {
  overflow: hidden;
}
  .callback-layout .structure {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 15px;
    overflow: unset;
  }

  /* Header */
  .header {
    position: relative;
    z-index: 5;
  }
    .header-top-panel {
      background: #000;
      box-shadow: 0px 2px 2px #ccc;
      position: relative;
      z-index: 2;
    }
    .header-top-panel > .wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
    }
      .header-top-panel > .wrapper > div:first-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        flex: 1 1 auto;
      }
        .header-social-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 4px 5px 5px 0px;
          margin: 0px 0px 0px -8px;
        }
          .header-social-container > div {
            padding: 0px 0px 0px 8px;
            width: 33px;
            height: 25px;
          }
            .header-social-container a {
              cursor: pointer;
            }
              .header-social-container img {
                width: 100%;
              }

        .header-city-choose-container {
          position: relative;
          z-index: 1;
        }
          .header-city-label {
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            color: #fff;
            height: 100%;
            padding: 4px 0px 5px;
            white-space: nowrap;
            cursor: pointer;
            position: relative;
            z-index: 1;

            -webkit-animation: header-city-label-shake 20s infinite both;
            -o-animation: header-city-label-shake 20s infinite both;
            animation: header-city-label-shake 20s infinite both;
          }
            .header-city-label:hover,
            .header-city-choose-container.is-opened .header-city-label {
              -webkit-animation-name: none;
              -moz-animation-name: none;
              -o-animation-name: none;
              animation-name: none;
            }
            @keyframes header-city-label-shake {
              0%, 9%, 100% {
                -webkit-transform: translate3d(0,0,0);
                -moz-transform: translate3d(0,0,0);
                -ms-transform: translate3d(0,0,0);
                -o-transform: translate3d(0,0,0);
                transform: translate3d(0,0,0);
              }
              1%, 3%, 5%, 7% {
                -webkit-transform: translate3d(-2px,0,0);
                -moz-transform: translate3d(-2px,0,0);
                -ms-transform: translate3d(-2px,0,0);
                -o-transform: translate3d(-2px,0,0);
                transform: translate3d(-2px,0,0);
              }
              2%, 4%, 6%, 8% {
                -webkit-transform: translate3d(2px,0,0);
                -moz-transform: translate3d(2px,0,0);
                -ms-transform: translate3d(2px,0,0);
                -o-transform: translate3d(2px,0,0);
                transform: translate3d(2px,0,0);
              }
            }

            .header-city-label:after {
              content: "";
              border-width: 6px 6px 0px 6px;
              border-style: solid;
              border-color: #fff transparent;
              width: 0px;
              height: 0px;
              margin: 0px 0px 0px 10px;
            }
              .header-city-choose-container.is-opened .header-city-label:after {
                border-width: 0px 6px 6px 6px;
              }

            .header-city-label span {
              display: inline-block;
              line-height: 1.1;
              border-bottom: 1px solid #fff;
            }

          .header-cities-container {
            display: none;
            background: #000;
            border-radius: 5px;
            box-shadow: 0 0 5px rgba(0,0,0,0.1);
            line-height: 1;
            position: absolute;
            top: 100%;
            left: 0px;
            min-width: 100%;
            margin: 5px 0px 0px;
          }
            .header-city-choose-container.is-opened .header-cities-container {
              display: block;
            }

            .header-cities-container > div {
              white-space: nowrap;
              cursor: pointer;
            }
              .header-cities-container > div a,
              .header-cities-container > div span {
                color: #fff;
                display: block;
                padding: 8px 8px 8px 10px;
                text-decoration: none;
              }
                .header-cities-container > div:hover a,
                .header-cities-container > div:hover span {
                  text-decoration: underline;
                }


      .header-info-menu {
        flex: 5 1 auto;
      }
        .header-info-menu ul {
          display: flex !important;
          flex-direction: row;
          justify-content: flex-end;
          align-items: stretch;
          font-size: 13px;
          height: 100%;
          white-space: nowrap;
        }
        .header-info-menu > ul {
          margin: 0px -6px;
        }

          .header-info-menu li {
            padding: 0px 0px 0px 6px;
          }
          .header-info-menu li.info-item {
            padding: 0px;
          }

            .header-info-menu div {
              height: 100%;
            }
            .header-info-menu li.info-item > div {
              display: none;
            }

              .header-info-menu a {
                display: inline-flex;
                align-items: center;
                color: #fff;
                height: 100%;
                padding: 6px 4px 8px;
                text-decoration: underline;
                cursor: pointer;
              }


    .header-content {
      padding: 15px 0px;
      white-space: nowrap;
    }
      .header-content > .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
      }
        .header-left {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          flex-grow: 2;
        }
          .header-slogan {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            line-height: 1.2;
            margin: 10px 5vw 0px 25px;
          }

        .header-contacts {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: stretch;
          flex-grow: 3;
          font-size: 18px;
          line-height: 1.2;
        }
          .header-contacts:before,
          .header-contacts:after {
            content: "";
            order: 2;
            background: #000;
            width: 1px;
            margin: 0px 0px 0px 18px;
          }
          .header-contacts:after {
            order: 4;
          }

          .header-contacts > div {
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin: 0px 0px 0px 18px;
          }
          /*.header-contacts > div + div {
            border-left: 1px solid #000;
            padding: 0px 0px 0px 18px;
          }*/
          .header-worktime {
            order: 1;
          }
            .header-worktime:before {
              content: "";
              background: url('/i/header-icon-time.svg') no-repeat 50% 50%/contain transparent;
              width: 35px;
              height: 35px;
              margin: 0px 10px 0px 0px;
            }

          .header-address {
            order: 3;
          }
            .header-address:before {
              content: "";
              background: url('/i/header-icon-address.svg') no-repeat 50% 50%/contain transparent;
              width: 28px;
              height: 35px;
              margin: 0px 10px 0px 0px;
            }

            .header-phones {
              order: 5;
              font-size: 20px;
            }


    .header-menu-container {
      background: #7fffec;
      line-height: 1.2;
    }
      .header-menu-container > .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
        .header-menu-button-container,
        .header-menu-checkbox,
        .header-menu-close-button {
          display: none;
        }

        .header-menu-container > .wrapper > ul {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          flex-grow: 1;
          font-size: 17px;
          position: relative;
        }
          .header-menu-container > .wrapper > ul > li {
            flex: 1 1 auto;
            position: relative;
          }
            .header-menu-container > .wrapper > ul > li > div a,
            .header-menu-container > .wrapper > ul > li > div span {
              display: block;
              font-size: 18px;
              font-weight: 500;
              text-align: center;
              text-decoration: none;
              white-space: nowrap;
              padding: 12px 10px;
            }
            .header-menu-container > .wrapper > ul li > div span {
              cursor: default;
            }
            .header-menu-container > .wrapper > ul li > div a {
              cursor: pointer;
            }
              .header-menu-container > .wrapper > ul > li > div div:hover a,
              .header-menu-container > .wrapper > ul > li.active > div a {
                color: #fff;
                background: #535353;
              }
              .header-menu-container > .wrapper > ul li.active > div a {
                cursor: default;
              }

            .header-menu-container > .wrapper > ul li > div > .dropmarker {
              display: none;
            }

            .header-menu-container > .wrapper > ul > li > ul {
              background: #fff;
              position: absolute;
              top: 100%;
              left: 0px;
              min-width: 100%;
              padding: 20px 30px;
              box-shadow: 2px 2px 6px #6b6868;

              visibility: hidden;
              opacity: 0;
            }
              .header-menu-container > .wrapper > ul > li.with-groups > ul {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: stretch;
                padding: 20px 15px;
              }
              .header-menu-container > .wrapper > ul > li:hover > ul {
                visibility: visible;
                opacity: 1;
              }

              .header-menu-container > .wrapper > ul > li.with-groups > ul > li {
                padding: 0px 15px;
              }
              .header-menu-container > .wrapper > ul > li.with-groups > ul > li.icn-menu-service-group-null {
                border-left: 1px solid #000;
              }
                  .header-menu-container > .wrapper > ul > li > ul > li.icn-menu-service-group-null > div {
                    display: none;
                  }

                  .header-menu-container > .wrapper > ul > li ul > li > div a,
                  .header-menu-container > .wrapper > ul > li ul > li > div span {
                    display: block;
                    padding: 4px 0px;
                    white-space: nowrap;
                  }
                    .header-menu-container > .wrapper > ul > li ul > li.active > div a,
                    .header-menu-container > .wrapper > ul > li ul > li > div a:hover {
                      text-decoration: underline;
                    }

                  .header-menu-container > .wrapper > ul > li.with-groups > ul > li > div a,
                  .header-menu-container > .wrapper > ul > li.with-groups > ul > li > div span {
                    font-size: 20px;
                    font-weight: 500;
                    padding: 0px 0px 8px;
                  }

        .header-menu-container .callback-button-style {
          color: #000;
          background: transparent;
          border: 2px solid #000;
          border-radius: 4px;
          box-shadow: none;
          min-width: 0px;
          padding: 0.4375em 1em;
          margin: 0px 0px 0px 35px;
        }
          .header-menu-container .callback-button-style:hover {
            color: #fff;
            background: #000;
          }


  /* Content block */
  .content {
    position: relative;
    overflow: hidden;
    z-index: 3;

    /*min-height: 3000px;*/
  }
    .header-slide-block {
      padding: 0px 0px 30px;
    }
      .header-slide-block > div {
        background: no-repeat top left/cover transparent;
        padding: 0px 20px 15px;
      }
        .header-slide-block > div > div {
          max-width: 1280px;
          margin: 0px auto;
        }

        .header-slide-block .subheader-wrapper {
          vertical-align: baseline;
        }

        .header-slide-block .subheader {
          color: #fff;
          font-size: 38px;
          padding: 20px 0px 0px;
          vertical-align: baseline;
        }
          .header-slide-block.service-slide .subheader {
            color: #fff;
            font-size: 58px;
            font-weight: 400;
            padding: 25px 0px 0px;
          }

        .header-slide-block .subheader-descr {
          color: #fff;
          font-size: 36px;
          text-align: center;
          padding: 5px 0px 0px;
          vertical-align: baseline;
        }
          .header-slide-block.service-slide .subheader-descr {
            color: #fff;
            font-size: 40px;
            font-weight: 300;
          }

        .header-slide-block .slide-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: stretch;
          color: #fff;
          padding: 25px 0px 0px;
        }
          .header-slide-block .slide-content > div {
            flex: 1 1 auto;
          }
          .header-slide-block .slide-content > div:first-child {
            font-size: 20px;
            font-weight: 500;
            padding: 25px 0px 0px;
          }

            .header-slide-block .slide-content > div:first-child ul.ul {
              list-style: none;
              margin-left: 0em;
              margin-bottom: 15px;
            }
              .header-slide-block.service-slide .slide-content > div:first-child ul.ul {
                margin-bottom: 5px;
              }

              .header-slide-block .slide-content > div:first-child ul.ul li {
                padding-left: 1.5em;
                padding-bottom: 0.5em;
              }
                .header-slide-block.service-slide .slide-content > div:first-child ul.ul li {
                  padding-bottom: 1em;
                }

                .header-slide-block .slide-content > div:first-child ul.ul li:before {
                  background: #7fffec;
                  border-radius: 50%;
                  width: 0.5em;
                  height: 0.5em;
                }
                  .header-slide-block.service-slide .slide-content > div:first-child ul.ul li:before {
                    background: url('/i/ul-list-item-tick.svg') no-repeat 50% 50%/contain transparent;
                    border-radius: unset;
                    width: 0.7em;
                    height: 1em;
                  }

            .header-slide-block .slide-content > div:first-child .button-style {
              box-shadow: none;
              font-size: 19px;
              font-weight: 700;
              padding: 0.84210526em 2.36842105em;
            }
              .header-slide-block .slide-content > div:first-child .button-style.with-hand,
              .header-slide-block .slide-content > div:first-child .button-style.with-hand-white {
                margin-bottom: 41px;
              }
                .header-slide-block .slide-content > div:first-child .button-style.with-hand:after,
                .header-slide-block .slide-content > div:first-child .button-style.with-hand-white:after {
                  width: 28px;
                  height: 40px;
                  margin-top: 1px;
                }

            .header-slide-block .slide-content > div:first-child .video-button {
              background: #fff;
              border: 2px dashed #40312a;
              font-size: 17px;
              padding: 1em 2em 1em 3.52941176em;
            }
              .header-slide-block .slide-content > div:first-child .video-button:before {
                content: "";
                display: block;
                width: 0px;
                height: 0px;
                border-top: 0.58823529em solid transparent;
                border-left: 1em solid #40312a;
                border-bottom: 0.58823529em solid transparent;
                position: absolute;
                top: 50%;
                left: 1.70588235em;
                margin: -0.58823529em 0em 0em;
              }


          .header-slide-block .slide-content > div + div {
            min-width: 530px;
            max-width: 530px;
            padding-left: 105px;
          }

            .header-slide-block .slide-content .best-company {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              font-size: 18px;
              text-align: center;
              margin: 0px 0px 15px -105px;
            }
              .header-slide-block .slide-content .best-company span {
                font-size: 20px;
              }

              .header-slide-block .slide-content .best-company > div {
                flex: 1 1 auto;
              }
              .header-slide-block .slide-content .best-company > div:first-child {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-width: 105px;
                max-width: 105px;
                height: 0px;
                padding-right: 15px;
              }
                .header-slide-block .slide-content .best-company > div:first-child img {
                  width: 100%;
                }


            .header-slide-block .slide-content .form-block-container {
              color: #000;
              background: url('/i/header-slide-form-bg.svg') repeat-x 50% 50%/auto 100% #7fffec;
              border: 3px dashed #000;
              border-radius: 30px;
              padding: 20px 24px 24px;
              margin: 0px 0px 20px;
            }
              .header-slide-block.service-slide .slide-content .form-block-container {
                color: #fff;
                background: #535353;
                border: none;
                border-radius: 20px;
              }

              .header-slide-block .slide-content .form-block-container > .subsubheader {
                font-size: 22px;
                font-weight: 700;
                line-height: 1.3;
                text-transform: none;
                text-align: center;
                padding: 0px 0px 15px;
                margin-top: 0px;
              }
                .header-slide-block.service-slide .slide-content .form-block-container > .subsubheader {
                  font-weight: 500;
                }

              .header-slide-block .slide-content .form-block-container .form-row + .form-row {
                margin-top: 10px;
              }
                .header-slide-block .slide-content .form-block-container .form-label {
                  display: none;
                }
                .header-slide-block .slide-content .form-block-container .input-container {
                  font-size: 18px;
                  border: none;
                  border-radius: 10px;
                  box-shadow: none;
                  padding: 10px 18px;
                }

                .header-slide-block .slide-content .form-block-container .input-container input,
                .header-slide-block .slide-content .form-block-container .errorMessage {
                  text-align: center;
                }

                .header-slide-block .slide-content .form-block-container .form-buttons {
                  display: block;
                  margin: 0px;
                }
                  .header-slide-block .slide-content .form-block-container .form-button {
                    padding: 10px 0px 0px;
                  }
                    .header-slide-block .slide-content .form-block-container .form-button .button-style {
                      color: #fff;
                      display: block;
                      font-size: 18px;
                      text-align: center;
                      background: #000;
                      border: none;
                      box-shadow: none;
                      border-radius: 10px;
                      width: 100%;
                      padding: 12px 18px;
                    }
                      .header-slide-block .slide-content .form-block-container .form-button .button-style.with-hand-white:after {
                        background-image: url('/i/hand--black.svg');
                      }

                    .header-slide-block.service-slide .slide-content .form-block-container .form-button .button-style {
                      background: #04bda1;
                    }
                      .header-slide-block.service-slide .slide-content .form-block-container .form-button .button-style.with-hand-white:after {
                        background-image: url('/i/hand--white.svg');
                      }

            .header-slide-block .slide-content .discount-text {
              font-size: 17px;
              text-align: center;
            }


    .breadcrumbs {
      font-size: 0px;
      padding: 15px 0px;
    }
      .breadcrumbs li {
        display: inline;
        font-size: 0px;
        vertical-align: baseline;
      }
        .breadcrumbs li:before,
        .breadcrumbs a,
        .breadcrumbs span {
          font-size: 12px;
          vertical-align: baseline;
          line-height: 1.1;
        }
        .breadcrumbs li + li:before {
          content: "→";
          font-size: 13px;
          display: inline-block;
          margin: 0px 5px;
        }
        .breadcrumbs a {
          color: #04bda1;
          cursor: pointer;
          text-decoration: underline;
        }
          .breadcrumbs a:hover {
            text-decoration: none;
          }


    .subheader {
      font-size: 40px;
      font-weight: 400;
      line-height: 1.3;
      padding: 35px 0px 25px;
      text-align: center;
      position: relative;
      z-index: 2;
    }
      h1.subheader {
        font-weight: 500;
        padding-top: 25px;
      }
      .text-container + .subheader {
        padding-top: 15px;
      }

    .subsubheader {
      font-size: 20px;
      font-weight: 500;
      padding: 15px 0px 20px;
      position: relative;
      z-index: 2;
    }
      .subsubheader,
      .subsubheader * {
        text-transform: uppercase;
      }
      h1.subsubheader {
        font-size: 24px;
        padding-top: 10px;
      }


    .text-container {
      padding: 0px 0px 20px;
      position: relative;
      z-index: 1;
    }


    .incut {
      font-size: 21px;
      text-align: center;
      line-height: 1.3;
      padding: 12px 0px;
      position: relative;
    }
      .incut:before {
        content: "";
        background: url('/i/incut-bg.svg') repeat-y 50% 50%/auto transparent;
        position: absolute;
        top: 0px;
        left: -9999px;
        right: -9999px;
        bottom: 0px;
        margin: auto;
        z-index: 0;
      }
      .incut > * {
        position: relative;
        z-index: 1;
      }


    .js-tabs {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      margin: 0px 0px 28px;
    }
      .js-tabs > div > a {
        display: block;
        line-height: 1.3;
        background: transparent;
        border-radius: 20px 20px 0px 0px;
        padding: 12px 15px 10px;
        white-space: nowrap;
        position: relative;
      }
        .js-tabs > div > a.active {
          background: #04bda1;
        }

        .js-tabs > div > a:after {
          content: "";
          display: block;
          border-width: 10px 12px 0px 12px;
          border-style: solid;
          border-color: #04bda1 transparent;
          position: absolute;
          top: 100%;
          left: 0px;
          right: 0px;
          width: 0px;
          height: 0px;
          margin: auto;
          opacity: 0;
        }
          .js-tabs > div > a.active:after {
            opacity: 1;
          }


    .quick-link-list {
      padding-bottom: 22px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      z-index: 1;
    }
      .quick-link-list > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: flex-start;
        align-items: stretch;
        margin: -12px 0px 0px -12px;
      }
        .quick-link-list > div > div {
          padding: 12px 0px 0px 12px;
        }
          .quick-link-list a {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            border: 2px solid #04bda1;
            border-radius: 10px;
            font-size: 12px;
            font-weight: 700;
            line-height: 1.1;
            text-align: center;
            text-transform: uppercase;
            height: 100%;
            padding: 10px 12px;
            cursor: pointer;
            position: relative;
          }
            .quick-link-list a:hover,
            .quick-link-list div.active a,
            .quick-link-list div a.active,
            .price-groupped-table-container .quick-link-list:not(.selected) > div > div:first-child a {
              background: #7fffec;
            }
            .quick-link-list div.active a,
            .quick-link-list div a.active,
            .price-groupped-table-container .quick-link-list:not(.selected) > div > div:first-child a {
              cursor: default;
            }


    .news-groups-links > div,
    .groups-quick-links > div {
      justify-content: flex-start;
      margin: -6px 0px 0px -6px;
    }
      .news-groups-links > div > div,
      .groups-quick-links > div > div {
        padding: 6px 0px 0px 6px;
      }
        .news-groups-links > div > div > span,
        .groups-quick-links > div > div > span {
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
          font-weight: 700;
        }
        .news-groups-links a,
        .groups-quick-links a {
          background: #f1f1f1;
          border: none;
          font-size: 13px;
          font-weight: 400;
          text-transform: none;
          padding: 8px 10px;
        }


    .news-block-container .list-view {
      margin-top: -30px;
    }
      .news-groups-links + .news-block-container .list-view {
        overflow: hidden;
      }

      .news-items {
        flex-grow: 1;
      }
        .news-block-container .list-view-item {
          width: 25%;
          padding-top: 30px;
        }
          .news-block-container .list-view-item-wrapper {
            border: none;
          }
            .news-block-container .list-view-item-image {
              border: 1px solid #f0f0f0;
            }

            .news-block-container .list-view-item-name a,
            .news-block-container .list-view-item-name span{
              font-size: 16px;
            }


    .news-page-image {
      text-align: center;
    }


    .gallery-block-container > div {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;

      font-style: italic;
      padding: 0px 0px 30px;
      margin: -10px 0px 0px -10px;
      position: relative;
    }
      .gallery-item {
        padding: 10px 0px 0px 10px;
        position: relative;
      }
        .gallery-item.gallery-item-width-2 { width: 50%; }
        .gallery-item.gallery-item-width-3 { width: 33.3333333%; }
        .gallery-item.gallery-item-width-4 { width: 25%; }

        .gallery-item > a {
          display: block;
          position: relative;
        }
        .gallery-item > a[data-fancybox] {
          -webkit-transition: -webkit-transform .3s ease 0s, -moz-transform .3s ease 0s, -ms-transform .3s ease 0s, -o-transform .3s ease 0s, transform .3s ease 0s, box-shadow .3s ease 0s;
          -moz-transition: -webkit-transform .3s ease 0s, -moz-transform .3s ease 0s, -ms-transform .3s ease 0s, -o-transform .3s ease 0s, transform .3s ease 0s, box-shadow .3s ease 0s;
          -o-transition: -webkit-transform .3s ease 0s, -moz-transform .3s ease 0s, -ms-transform .3s ease 0s, -o-transform .3s ease 0s, transform .3s ease 0s, box-shadow .3s ease 0s;
          transition: -webkit-transform .3s ease 0s, -moz-transform .3s ease 0s, -ms-transform .3s ease 0s, -o-transform .3s ease 0s, transform .3s ease 0s, box-shadow .3s ease 0s;
        }
          .gallery-item > a[data-fancybox]:hover {
            box-shadow: 0 2px 20px 2px rgba(0,0,0,.45);
            z-index: 1;
            -webkit-transform: scale(1.04);
            -moz-transform: scale(1.04);
            -ms-transform: scale(1.04);
            -o-transform: scale(1.04);
            transform: scale(1.04);
          }

          .gallery-item img {
            width: 100%;
          }

      .gallery-notice {
        font-weight: 500;
        text-align: center;
        margin: -10px 0px 0px !important;
      }


    .list-view,
    .news-items {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: stretch;
      position: relative;
    }
    .list-view {
      margin: -12px 0px 0px -12px;
      padding: 0px 0px 30px;
    }
      .list-view-item {
        width: 25%;
        padding: 12px 0px 0px 12px;
        position: relative;
      }
        .list-view-item-wrapper {
          border: 1px solid #f0f0f0;
          height: 100%;
          position: relative;
        }
          .list-view-item-image {
            font-size: 0px;
            position: relative;
            z-index: 1;
          }
            .list-view-item-image > a,
            .list-view-item-image > span {
              display: block;
              height: 0px;
              background: no-repeat 50% 50%/cover #eee;
              padding-top: 65%;
            }
              .list-view-item-image > a {
                cursor: pointer;
              }

          .list-view-item-content {
            padding: 13px 20px;
          }
            .list-view-item-name {
              position: relative;
            }
              .list-view-item-name a,
              .list-view-item-name span {
                font-size: 18px;
                text-decoration: none;
              }
              .list-view-item-name a {
                cursor: pointer;
              }
                .list-view-item-image:hover + .list-view-item-content .list-view-item-name a,
                .list-view-item-name a:hover {
                  text-decoration: underline;
                }

          .list-view-item-notice {
            padding: 12px 0px 0px;
          }
            .list-view-item-notice *:last-child {
              margin-bottom: 0px;
            }


    .list-block-container + .list-page-group-name {
      padding-top: 15px;
    }
    .list-page-group-name ~ .list-block-container + .list-block-container {
      padding-top: 30px;
    }


    .portfolio-page-gallery-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin: -20px 0px 0px -20px;
    }
      .portfolio-page-gallery-container > div {
        width: 50%;
        padding: 20px 0px 25px 20px;
      }
        .portfolio-page-gallery {
          position: relative;
        }
          .portfolio-page-gallery:before {
            content: "";
            display: block;
            padding: 75% 0% 0%;
            height: 0px;
          }
            .portfolio-page-gallery.slick-initialized:before {
              display: none;
            }

          .portfolio-gallery-item {
            position: relative;
            z-index: 1;
          }
            .portfolio-page-gallery > .portfolio-gallery-item {
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
            }
              .portfolio-page-gallery > .portfolio-gallery-item:first-child {
                z-index: 2;
              }

              .portfolio-gallery-item > div {
                background: no-repeat 50% 50%/contain transparent;
                position: relative;
              }
                .portfolio-gallery-item > div:before {
                  content: "";
                  display: block;
                  height: 0px;
                  padding: 75% 0% 0%;
                }
                .portfolio-gallery-item > div a {
                  content: "";
                  display: block;
                  font-size: 0px;
                  cursor: pointer;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  z-index: 1;
                }


          .portfolio-gallery-arrows {
            height: 0px;
          }
            .portfolio-gallery-arrows div {
              position: absolute;
              top: 0px;
              left: 0px;
              bottom: 0px;
              width: 100px;
              cursor: pointer;
            }
            .portfolio-gallery-arrows .slick-next {
              left: auto;
              right: 0px;
            }
              .portfolio-gallery-arrows div:before {
                content: "";
                background: url('/i/portfolio-gallery-arrow--left.svg') no-repeat 50% 50%/13px auto #000;
                border: 3px solid #fff;
                border-radius: 50%;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                margin: auto;
                width: 56px;
                height: 56px;
                cursor: pointer;
              }
                .portfolio-gallery-arrows .slick-next:before {
                  background-image: url('/i/portfolio-gallery-arrow--right.svg');
                }


          .portfolio-gallery-pager ul {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: flex-start;
            padding: 2px 0px 0px;
            margin: 0px 0px 0px -5px;
          }
            .portfolio-gallery-pager li {
              width: 10%;
              padding: 5px 0px 0px 5px;
            }
              .portfolio-gallery-pager div {
                background: no-repeat 50% 50%/cover transparent;
                border: 2px solid transparent;
                cursor: pointer;

                -webkit-transition: border-color .3s ease 0s, box-shadow .3s ease 0s;
                -moz-transition: border-color .3s ease 0s, box-shadow .3s ease 0s;
                -o-transition: border-color .3s ease 0s, box-shadow .3s ease 0s;
                transition: border-color .3s ease 0s, box-shadow .3s ease 0s;
              }
                .portfolio-gallery-pager .slick-active div {
                  border-color: rgba(0,0,0,.15);
                }

                .portfolio-gallery-pager div:before {
                  content: "";
                  display: block;
                  height: 0px;
                  padding: 65% 0% 0%;
                }


      .portfolio-page-desc {
        font-size: 18px;
        text-align: left;
      }
        .portfolio-page-desc p {
          text-align: left;
        }
        .portfolio-page-desc p + p {
          margin-top: -4px;
        }


    .advantages-block-container .list-view {
      align-items: flex-start;
      margin: -25px 0px 0px -25px;
    }
      .advantages-block-container .list-view-item {
        width: 33.33333333%;
        padding: 25px 0px 0px 25px;
      }
        .advantages-block-container .list-view-item-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          border: none;
          margin: 0px 0px 5px;
        }
          .advantages-block-container .list-view-item-image {
            width: 40px;
            min-width: 40px;
          }
            .advantages-block-container .list-view-item-image > a,
            .advantages-block-container .list-view-item-image > span {
              background: none;
              height: auto;
              text-align: center;
              padding-top: 0%;
            }
            .advantages-block-container .list-view-item-image img {
              width: 100%;
            }

          .advantages-block-container .list-view-item-content {
            align-self: center;
            padding: 0px 0px 0px 20px;
          }
            .advantages-block-container .list-view-item-name {
              line-height: 1.3;
            }
              .advantages-block-container .list-view-item-name a,
              .advantages-block-container .list-view-item-name span {
                font-weight: 500;
              }
              .advantages-block-container .list-view-item-name a {
                text-decoration: underline;
              }
                .advantages-block-container .list-view-item-name a:hover {
                  text-decoration: none;
                }


    .team-member-block-container .list-view-item {
      width: 25%;
    }
      .team-member-block-container .list-view-item-image {
        padding: 15px;
      }
        .team-member-block-container .list-view-item-image a,
        .team-member-block-container .list-view-item-image span {
          border-radius: 50%;
          padding-top: 100%;
        }

      .team-member-block-container .list-view-item-content {
        text-align: center;
      }
        .team-member-block-container .list-view-item-name {
          font-weight: 500;
        }
        .team-member-block-container .list-view-item-notice {
          padding: 5px 0px 0px;
        }


    .team-member-page-info {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
      .team-member-page-info > div:first-child {
        width: 45%;
        min-width: 45%;
        padding: 0px 0px 25px;
      }
        .team-member-page-info > div:first-child span {
          display: block;
          background: no-repeat 50% 50%/cover #eee;
          border-radius: 50%;
          width: 70%;
          max-width: 290px;
          margin: 0px auto;
        }
          .team-member-page-info > div:first-child span:after {
            content: "";
            display: block;
            height: 0px;
            padding: 100% 0% 0%;
          }

      .team-member-page-info > div + div {
        font-size: 16px;
        text-align: left;
        width: 55%;
        min-width: 55%;
      }
        .team-member-page-info > div + div p {
          text-align: left;
        }


    .price-table {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      padding: 0px 0px 30px;
      position: relative;
    }
      .price-table-counter {
        counter-reset: price-table-group-counter;
      }
      .price-table > div {
        border-bottom: 4px solid #f9f9f8;
      }
        .price-groupped-table-container .price-table:not(.selected) > div:not(:first-child),
        .price-groupped-table-container .price-table.selected > div:not(.active),
        .price-groupped-table-container .price-table:not(.price-page-table) .price-table-group {
          display: none;
        }

      .price-table > div + div {
        margin-top: 30px;
      }
        .price-groupped-table-container .price-table > div + div {
          margin-top: 0px;
        }

        .price-table > div > div {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
        }
        .price-table-group {
          justify-content: flex-start !important;
          padding: 0px 0px 12px 28px;
        }
          .price-table-counter .price-table-group {
            counter-reset: price-table-subheader-counter;
          }
          .price-table-group span {
            color: #04bda1;
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase;
          }
            .price-table-counter .price-table-group span:before {
              content: counter(price-table-group-counter) ". ";
              counter-increment: price-table-group-counter;
            }

        .price-table-head {
          background: #7fffec;
        }

        .price-table-subheader {
          background: #fff;
          position: relative;
          z-index: 1;
        }

        .price-table-head,
        .price-table-subheader,
        .price-table-row {
          border-right: 4px solid #f9f9f8;
        }
        .price-table-row {
          background: #f2f2f1;
        }
          .price-table-row.odd,
          .price-table-design .price-table-row:nth-child(odd) {
            background: #e4e4e4;
          }
          .price-name,
          .price-price,
          .price-unit {
            font-weight: 400;
            padding: 5px;
            flex-basis: 100%;
            flex-shrink: 2;
          }
          .price-name {
            padding-left: 25px;
          }
          .price-price,
          .price-unit {
            text-align: center;
            flex-shrink: 3;
          }
            .price-table-head > span,
            .price-table-subheader > span,
            .price-table-row > span {
              border-width: 4px 0px 0px 4px;
              border-style: solid;
              border-color: #f9f9f8;
            }

            .price-price > span {
              font-size: inherit;
              width: 0px;
              height: 0px;
              padding: 0px 2px 0px;
            }

            .price-table-head .price-name,
            .price-table-head .price-price,
            .price-table-head .price-unit {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-end;
              font-size: 16px;
              font-weight: 500;
              line-height: 1.1;
              padding-top: 8px;
              padding-bottom: 8px;
            }
            .price-table-head .price-name {
              justify-content: flex-start;
            }
            .price-table-subheader .price-name {
              color: #04bda1;
              font-weight: 700;
              text-transform: uppercase;
              padding-top: 10px;
              padding-bottom: 8px;
            }
              .price-table-counter .price-table-subheader .price-name:before {
                content: counter(price-table-group-counter) "." counter(price-table-subheader-counter) ". ";
                counter-increment: price-table-subheader-counter;
                font-weight: 500;
              }
              .price-table-row + .price-table-subheader .price-name {
                border-top: 1px solid #e8e8e8;
              }


    .design-contents-rotator {
      padding: 0px 50px 50px 40px;
      position: relative;
    }
      .design-contents-rotator > div:first-child:not(.slick-initialized),
      .design-contents-rotator .slick-track {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
      }
        .design-contents-rotator .item {
          display: flex !important;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
          float: none !important;
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          height: unset !important;
        }
          .design-contents-rotator .slick-initialized .item {
            min-width: unset;
          }

          .design-contents-rotator .item > div:first-child {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            width: 66.66666667%;
            margin-top: -10px;
          }
          .photo-text-rotator .item > div:first-child {
            width: 50%;
          }

            .design-contents-rotator .item > div:first-child > div {
              text-align: center;
              width: 100%;
              height: 100%;
              padding: 10px 0px 0px 10px;
            }
              .design-contents-rotator .item > div:first-child > div a {
                cursor: pointer;
              }
                .design-contents-rotator .item > div:first-child > div img {
                  display: inline-block;
                  border: 1px solid #c7c7c7;
                  max-width: 100%;
                  max-height: 100%;
                  opacity: 0;
                }
                  .design-contents-rotator .item > div:first-child > div img.loaded {
                    opacity: 1;
                  }

          .design-contents-rotator .item > div + div {
            line-height: 1.3;
            width: 33.33333333%;
            padding-left: 40px;
          }
          .photo-text-rotator .item > div + div {
            width: 50%;
          }

            .design-contents-rotator .item > div + div .title {
              font-weight: 700;
              padding: 0px 0px 25px;
            }
            .design-contents-rotator .item > div + div p {
              text-align: left;
            }

      .design-contents-rotator-arrows {
        height: 0px;
      }
        .design-contents-rotator-arrows div {
          background: no-repeat 0% 50%/40px auto #fff;
          position: absolute;
          top: 0px;
          bottom: 0px;
          width: 50px;
          cursor: pointer;
          z-index: 1;
        }
        .design-contents-rotator-arrows .slick-prev {
          background-image: url('/i/rotator-arrow--left.svg');
          left: 0px;
        }
        .design-contents-rotator-arrows .slick-next {
          background-image: url('/i/rotator-arrow--right.svg');
          background-position: 100% 50%;
          right: 0px;
        }

      .design-contents-rotator-dots {
        font-size: 0px;
        position: relative;
        z-index: 1;
      }
        .design-contents-rotator-dots ul {
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          align-content: flex-end;
          align-items: flex-end;
          margin: 16px 0px 0px -4px;
        }
          .design-contents-rotator-dots li {
            padding: 4px 0px 0px 4px;
          }
            .design-contents-rotator-dots li div {
              background: #ccc;
              border-radius: 50%;
              width: 10px;
              height: 10px;
              cursor: pointer;
            }
            .design-contents-rotator-dots li.slick-active div {
              background: #000;
            }


    .callback-content-block,
    .price-economy-block {
      background: url('/i/callback-content-bg.jpg') no-repeat 0% 50%/cover transparent;
      padding: 20px 50px 30px;
    }
      .callback-content-block > div,
      .price-economy-block > div {
        color: #fff;
        text-align: center;
        max-width: 900px;
        margin: auto;
      }
        .callback-content-block .subheader,
        .price-economy-block .subheader {
          font-size: 48px;
          font-weight: 500;
        }
        .callback-content-block .text-wrapper,
        .price-economy-block .text-wrapper {
          font-size: 22px;
          padding: 10px 0px 30px;
        }

        .callback-content-block .form-block-container {
          background-color: transparent;
          max-width: 780px;
          padding: 0px 0px 30px;
          margin: auto;
        }
          .callback-content-block .form {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
          }
            .callback-content-block .form-row {
              flex: 1 0 auto;
            }
              .callback-content-block .form-row + .form-row {
                margin: 0px 0px 0px 10px;
              }

              .callback-content-block .form-label {
                display: none;
              }

              .callback-content-block .input-container {
                padding: 8px 12px;
              }

              .callback-content-block .form-buttons {
                margin: 0px;
              }
                .callback-content-block .form-button {
                  padding: 0px 0px 0px 20px;
                }

        .price-economy-block .economy-button {
          border: none;
          box-shadow: none;
          font-size: 19px;
          font-weight: 700;
          padding: 0.84210526em 2.36842105em;
        }


    .certs-rotator,
    .response-rotator,
    .response-list,
    .partners-rotator,
    .partners-list {
      position: relative;
    }
      .response-list,
      .partners-list {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: flex-start;
        align-items: stretch;
        margin: -30px 0px 0px;
        z-index: 1;
      }
        .response-list.slick-initialized,
        .partners-list.slick-initialized {
          display: block;
          margin-left: 40px;
          margin-right: 40px;
        }

        .response-item {
          padding: 30px 20px 25px;
        }
          .response-list > .response-item {
            width: 50%;
          }

          .response-item > div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            height: 100%;
          }
            .response-list.slick-initialized .response-item > div {
              height: auto;
            }

            .response-item > div > div:first-child {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: stretch;
              flex: 1 0 auto;
              background: #eee;
              border-radius: 10px;
              padding: 20px 25px 20px 20px;
              position: relative;
            }
              .bgcef .response-item > div > div:first-child {
                background: #fff;
              }

              .response-item > div > div:first-child:after {
                content: "";
                display: block;
                border: 10px solid;
                border-color: #eee transparent transparent #eee;
                position: absolute;
                top: 100%;
                left: 35px;
                width: 0px;
                height: 0px;
              }
                .bgcef .response-item > div > div:first-child:after {
                  border-color: #fff transparent transparent #fff;
                }

              .response-item > div > div:first-child > div:first-child {
                font-style: italic;
                text-align: justify;
              }

              .response-item > div > div:first-child > div + div {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                align-items: flex-end;
              }
                .response-item > div > div:first-child > div + div > div {
                  flex-grow: 1;
                  padding: 5px 0px 0px;
                }
                .response-item > div > div:first-child > div + div > div:first-child {
                  color: #505050;
                  text-align: right;
                  padding-left: 5px;
                  white-space: nowrap;
                }
                .response-item > div > div:first-child > div + div > div + div {
                  width: 100%;
                }
                  .response-item > div > div:first-child > div + div > div + div audio {
                    display: block;
                    max-width: 100%;
                  }

            .response-item > div > div + div {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: stretch;
              padding: 32px 0px 0px 5px;
            }
              .response-item > div > div + div > div:first-child span {
                display: block;
                background: no-repeat 50% 50%/cover #eee;
                border-radius: 50%;
                width: 60px;
                height: 60px;
              }
                .bgcef .response-item > div > div + div > div:first-child span {
                  background-color: #fff;
                }

              .response-item > div > div + div > div + div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: stretch;
                padding: 0px 0px 0px 12px;
              }
                .response-item > div > div + div > div + div > div:first-child {
                  font-size: 16px;
                }
                .response-item > div > div + div > div + div > div + div {
                  color: #505050;
                  font-size: 12px;
                  font-style: italic;
                }



      .partners-list {
        padding: 0px 0px 25px;
        margin: -10px 0px 0px;
      }
        .partners-item {
          text-align: center;
          padding: 10px 10px 0px;
        }
          .partners-item img {
            margin: auto;
            max-width: 100%;
          }


      .certs-rotator .gallery-block-container {
        margin: 0px 0px -10px;
      }
        .certs-rotator .gallery-block-container > div.slick-initialized {
          padding: 0px 50px 30px;
        }
          .certs-rotator .gallery-item {
            width: 16.66666666%;
          }
          .certs-rotator .slick-track {
            padding: 0px 0px 10px;
          }
          .certs-rotator .gallery-item > a:hover {
            box-shadow: unset;
          }


      .certs-rotator-arrows,
      .response-rotator-arrows,
      .partners-rotator-arrows {
        font-size: 0px;
        height: 0px;
      }
        .certs-rotator-arrows > div,
        .response-rotator-arrows > div,
        .partners-rotator-arrows > div {
          background: no-repeat 50% 50%/contain #fff;
          position: absolute;
          top: 0px;
          bottom: 25px;
          width: 40px;
          cursor: pointer;
          z-index: 1;
        }
          .certs-rotator-arrows .slick-prev,
          .response-rotator-arrows .slick-prev,
          .partners-rotator-arrows .slick-prev {
            background-image: url('/i/rotator-arrow--left.svg');
            left: 0px;
          }
          .certs-rotator-arrows .slick-next,
          .response-rotator-arrows .slick-next,
          .partners-rotator-arrows .slick-next {
            background-image: url('/i/rotator-arrow--right.svg');
            right: 0px;
          }


    .textura-container {
      color: #1f0e05;
      background: url('/i/textura-bg.jpg') repeat 50% 50% #eae7e4;
      padding: 0px 40px;
    }
      .textura-container > .wrapper {
        max-width: 1280px;
      }


    .etapy-container {
      padding: 0px 0px 30px;
    }
      .etapy-container > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: flex-start;
        align-items: flex-start;
        margin: -30px 0px 0px -40px;
      }
        .etapy-container > div > div {
          width: 20%;
          padding: 40px 0px 0px 40px;
        }
          .etapy-container > div > div > div:first-child {
            position: relative;
          }
            .etapy-container > div > div > div:first-child span {
              font-size: 0px;
              display: block;
              background: #7fffec;
              border-radius: 50%;
              width: 60%;
              max-width: 130px;
              min-width: 100px;
              padding: 25px;
              margin: 0px auto 18px;
              position: relative;
              z-index: 1;
            }
              .etapy-container > div > div > div:first-child span:before {
                content: "";
                display: block;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: contain;
                height: 0px;
                padding-top: 100%;
              }
                .etapy-container > div > div:first-child > div:first-child span:before {
                  background-image: url('/i/step-icon-01.svg');
                }
                .etapy-container > div > div:nth-child(2) > div:first-child span:before {
                  background-image: url('/i/step-icon-02.svg');
                }
                .etapy-container > div > div:nth-child(3) > div:first-child span:before {
                  background-image: url('/i/step-icon-03.svg');
                }
                .etapy-container > div > div:nth-child(4) > div:first-child span:before {
                  background-image: url('/i/step-icon-04.svg');
                }
                .etapy-container > div > div:nth-child(5) > div:first-child span:before {
                  background-image: url('/i/step-icon-05.svg');
                }

            .etapy-container > div > div + div > div:first-child:after {
              content: "";
              display: block;
              height: 0px;
              position: absolute;
              top: 50%;
              left: 0px;
              border-bottom: 1px dashed #959595;
              right: 0;
              transform: translateX(-60%);
            }


          .etapy-container > div > div > div:first-child + div {
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            text-align: center;
            margin: 0px 0px 10px;
          }

          .etapy-container > div > div > div:first-child + div + div {
            text-align: center;
          }



  /* Contact */
  .contact-info-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    font-size: 18px;
    line-height: 1.4;
    padding: 0px 30px 15px;
    margin-top: -20px;
  }
    .contact-info-container > div {
      width: 50%;
      margin-left: -20px;
    }
    .contact-info-container > div > div {
      padding: 20px 0px 0px 20px;
    }
      .contact-info-title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 13px;
      }
      .contact-info-title + div > div + div {
        margin-top: 13px;
      }

      .contact-phones > div + div {
        font-size: 22px;
        font-weight: 700;
      }
        .contact-phones > div + div > div + div {
          margin-top: 5px;
        }

      .contact-phones-provision > div + div {
        font-size: 21px;
        font-weight: 500;
      }
        .contact-phones-provision > div + div > div + div {
          margin-top: 4px;
        }

  .contact-map-block-container {
    padding: 0px 0px 25px;
    position: relative;
  }
    .contact-map-block-container:before {
      content: "";
      display: block;
      height: 0px;
      padding-top: 45%;
      position: relative;
      z-index: 0;
    }

    .contact-map-placeholder {
      background: #eee;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 25px;
      z-index: 1;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
      .contact-map-placeholder:before {
        content: "Загрузка карты...";
      }

    #contact-map-container {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 25px;
      z-index: 2;
    }



  /* Footer */
  .footer-forms-tabs {
    background: #2e2e2e;
    align-items: stretch;
    margin: 0px;
  }
    .footer-forms-tabs > div {
      width: 25%;
    }
      .footer-forms-tabs > div > a {
        color: #fff;
        border-radius: unset;
        font-size: 16px;
        line-height: 1.2;
        text-align: center;
        padding: 18px 0px;
        position: relative;
      }
        .footer-forms-tabs > div > a:hover {
          color: #04bda1;
        }
        .footer-forms-tabs > div > a.active {
          color: #000;
          background: unset;
          font-weight: 500;
        }

        .footer-forms-tabs > div > a:after {
          content: "";
          display: block;
          border: 0px solid #505050;
          border-left-width: 1px;
          width: auto;
          height: auto;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          opacity: 1;
          z-index: 1;

          -webkit-transform: skew(-10deg);
          -moz-transform: skew(-10deg);
          -ms-transform: skew(-10deg);
          -o-transform: skew(-10deg);
          transform: skew(-10deg);
        }
          .footer-forms-tabs > div:first-child > a:after {
            left: -10px;
          }
          .footer-forms-tabs > div:last-child > a:after {
            right: -10px;
          }

          .footer-forms-tabs > div > a.active:after {
            background: url('/i/footer-forms-tab-bg.svg') repeat-x 50% 50%/auto 100% #04bda1;
          }

        .footer-forms-tabs > div > a span {
          text-transform: uppercase;
          position: relative;
          z-index: 2;
        }

  #footer-forms > div {
    background: url('/i/footer-form-order.jpg') no-repeat 100% 0%/cover #efefef;
    padding: 11px 0px 35px;
  }
      #footer-forms .form {
        width: 60%;
      }
        #footer-forms .form-elements {
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          align-content: flex-start;
          align-items: flex-start;
          margin: 0px 0px 0px -48px;
        }
        #footer-forms .footer-phone-form .form-elements {
          flex-flow: column nowrap;
        }

          #footer-forms .form-elements > div {
            width: 50%;
          }

          #footer-forms .form-elements .form-row {
            padding: 14px 0px 0px 48px;
            margin: 0px !important;
          }
            #footer-forms .form-elements .input-container {
              border-color: #ececec;
              border-radius: 3px;
              box-shadow: unset;
              padding: 8px 15px;
            }
              #footer-forms .form-elements .input-container.error {
                border-color: #04bda1;
              }

              #footer-forms .form-elements .input-container textarea {
                height: 94px;
              }

        #footer-forms .form-row-agreement {
          padding: 22px 0px 0px;
        }
          #footer-forms .form-row-agreement .errorMessage {
            display: inline-block;
          }

        #footer-forms .form-label {
          color: #000;
          font-size: 14px;
          font-weight: 400;
        }
        #footer-forms .form-button {
          padding-top: 22px;
        }


  .footer {
    background: url('/i/footer-bg-min.jpg') repeat 50% 50% transparent;
    padding: 25px 0px 0px;
    position: relative;
    z-index: 1;
  }
    .footer-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0px 0px 20px;
      margin-left: -40px;
    }
      .footer-copyright {
        flex: 1 0 auto;
        font-size: 13px;
        margin-left: 40px;
      }

      .footer-menu {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
        flex: 4 1 auto;
        margin: -8px 0px 0px 0px;
        position: relative;
      }
        .footer-menu > li {
          width: 33.33333333%;
          padding: 0px 0px 0px 30px;
          position: relative;
        }
          .footer-menu > li .dropmarker {
            display: none;
          }
          .footer-menu > li div > div > a,
          .footer-menu > li div > div > span {
            display: inline-block;
            color: #04bda1;
            font-size: 13px;
            padding: 7px 8px 8px;
          }
          .footer-menu > li div > div > a {
            cursor: pointer;
            text-decoration: underline;
          }
            .footer-menu > li div > div > a:hover {
              text-decoration: none;
            }


      .footer-contacts {
        flex: 0 0 auto;
        white-space: nowrap;
      }
        .footer-phones {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.3;
          text-align: center;
          padding: 0px 5px 10px 0px;
        }

        .footer-callback-link {
          text-align: center;
        }

        .footer-social-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 15px 0px 0px;
          margin: 0px 0px 0px -8px;
        }
          .footer-social-container > div {
            padding: 0px 0px 0px 8px;
            width: 32px;
            height: 24px;
          }
            .footer-social-container a {
              cursor: pointer;
            }
              .footer-social-container img {
                width: 100%;
              }


    .footer-bottom {
      margin: -15px 0px 0px -15px;
    }
      .footer-bottom > .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
      }
        .footer-bottom > .wrapper > div {
          padding: 15px 0px 0px 15px;
        }